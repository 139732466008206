import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Primary shoring under Epicore MSR' },
    { img: data.i2, caption: 'Primary Shoring' },
    { img: data.i3, caption: 'Reshoring with post shores' },
    { img: data.i4, caption: 'Ductwork while post shores still in place' },
    { img: data.i5, caption: 'Shoring and Reshoring' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: EPICORE MSR Shoring / Reshoring" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>EPICORE MSR Shoring / Reshoring</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>The Epicore MSR Composite Floor System must be securely shored prior to
            pouring concrete.  The primary shoring underneath the deck being poured should
            be spaced at a maximum of 5’ on center (consult Infinity for actual shoring
            requirements). The primary shoring should remain in place until the concrete
            reaches 75% compressive strength or at least 72 hours.  After relieving the primary
            shoring, the system must be reshored for two floors below the primary pour.</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/pouring-concrete/">
                Next Page: Pouring Concrete
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="/system-overview/mep-in-slab/">
                Prev Page: M/E/P in Slab
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/shoring-reshoring/gallery/01-Shong090_(33953).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i2: file(relativePath: { eq: "system-overview/shoring-reshoring/gallery/02-Shong130_(34311).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i3: file(relativePath: { eq: "system-overview/shoring-reshoring/gallery/03-Shong140_(33939).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i4: file(relativePath: { eq: "system-overview/shoring-reshoring/gallery/04-Shong070_(40699).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i5: file(relativePath: { eq: "system-overview/shoring-reshoring/gallery/05-Shong100_(33903).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`

